.messages-grid {
  display: flex;
  flex-direction: column;
}

.messages-grid__header {
  margin: 48px 0 0 0;

  color: var(--white);
  font-weight: 500;
  text-align: center;

  /* responsive */

  font-size: 24px;
  line-height: 32px;
}

.messages-grid__options {
  display: flex;

  /* responsive */

  border-radius: 16px;
  margin: 32px auto;
}

.messages-grid__option {
  border: 2px solid rgb(96, 96, 96);
  border-right: 0;
  background-color: var(--black);

  color: var(--white);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;

  cursor: pointer;
  user-select: none;
  outline: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  padding: 8px 24px;
  margin: 0;

  font-size: 16px;
  line-height: 24px;
}

.messages-grid__option:first-child {
  border-radius: 8px 0 0 8px;
}

.messages-grid__option:last-child {
  border-radius: 0 8px 8px 0;
  border-right: 2px solid rgb(96, 96, 96);
}

.messages-grid__option--active,
.messages-grid__option--active:hover {
  background-color: var(--white);
  color: var(--black);
}

@media only screen and (max-width: 540px) {
  .messages-grid__header {
    margin: 1.6rem 0 1.6rem 0;

    font-size: 2rem;
    line-height: 2.8rem;
  }

  .messages-grid__options {
    max-width: 24rem;
    flex-wrap: wrap;
    border-radius: 0.2rem;
    margin: 0.8rem auto 1.6rem;
  }

  .messages-grid__option {
    width: 12rem;
    padding: 0.4rem 1.6rem;

    border: 0.2rem solid rgb(96, 96, 96);

    font-size: 1.4rem;
  }

  .messages-grid__option:nth-child(1) {
    border-bottom-left-radius: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .messages-grid__option:nth-child(2) {
    border-top-right-radius: 0.8rem;
    border-bottom: 0;
  }

  .messages-grid__option:nth-child(3) {
    border-bottom-left-radius: 0.8rem;
    border-right: 0;
  }

  .messages-grid__option:nth-child(4) {
    border-top-right-radius: 0;
  }
}
