.auth0-features {
  padding: 3.2rem 6.4rem;
}

.neh-features__title,
.auth0-features__title {
  color: var(--white);
  font-weight: 600;
  text-align: center;
  margin-bottom: 4.8rem;
}

.neh-features__title {
  margin-top: 2rem;
  font-size: 6rem;
  line-height: 6rem;
}

.auth0-features__title {
  margin-top: 0;
  font-size: 3.2rem;
  line-height: 3.2rem;
}

.auth0-features__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6.4rem;
  row-gap: 6.4rem;
}

.auth0-feature {
  display: block;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  padding: 4.8rem;
  border-radius: 0.2rem;
}

.auth0-feature:hover {
  transform: scale(1.03);
}

.auth0-feature__headline {
  display: flex;
  align-items: center;
  margin-top: 0;
  color: var(--black);
  font-weight: 600;
  letter-spacing: -0.05rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.auth0-feature__icon {
  margin-right: 1.6rem;
}

.auth0-feature:hover .auth0-feature__headline {
  text-decoration: underline solid 2px var(--black);
  text-underline-offset: 2px;
  transition: all 0.3s ease-in-out 0s;
}

.auth0-feature__description {
  margin: 0;
  color: rgb(36, 36, 36);
  font-weight: 500;
  opacity: 0.7;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

@media only screen and (max-width: 900px) {
  .auth0-features__grid {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
  }

  .auth0-feature {
    padding: 3.2rem;
    border-radius: 0.2rem;
  }

  .auth0-feature__headline,
  .auth0-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media only screen and (max-width: 480px) {
  .auth0-features {
    padding: 1.6rem;
  }

  .neh-features__title {
    font-size: 4rem;
    line-height: 4rem;
    margin-top: 2.4rem;
    margin-bottom: 4rem;
  }

  .auth0-features__title {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 2.4rem;
  }

  .auth0-features__grid {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }

  .auth0-feature {
    padding: 3.2rem;
    border-radius: 0.2rem;
  }

  .auth0-feature__headline,
  .auth0-feature__description {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

span.neh-plan-price {
  font-size: 4.6rem;
  font-weight: 600;
  color: #363636;
}

span.neh-plan-billing_cycle {
  font-size: 1.6rem;
  font-weight: 600;
  color: #363636;
}

.margin-insert {
  margin-top: 12px; /* 必要に応じて調整せよ */
  margin-bottom: 12px; /* 必要に応じて調整せよ */
  display: block; /* ブロック要素として扱うことで、上下のマージンが効果的に適用される */
}

.neh-margin-2 {
  margin: 2px;
}
