.button {
  border: 0;
  border-radius: 0.2rem;

  font-family: var(--font-primary);
  font-weight: 600;
  color: var(--white);

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;

  /* responsive */

  min-width: 10rem;
  padding: 1.6rem 1.6rem;

  font-size: 1.6rem;
  line-height: 2.4rem;
}

.button--compact {
  /* responsive */

  padding: 1rem 1.6rem;

  font-size: 1.6rem;
  line-height: 1.6rem;
}

.button--primary {
  background-color: var(--indigo);
}

.button--primary:hover {
  background: rgba(99, 93, 255, 0.85);
}

.button--secondary {
  background-color: var(--white);
  color: var(--black);
}

.button--secondary:hover {
  background: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 480px) {
  .button {
    /* responsive */

    min-width: 0.8rem;
    padding: 1rem 1.2rem;

    font-size: 1.3rem;
    line-height: 2.2rem;
  }

  .button--compact {
    /* responsive */

    padding: 0.6rem 1rem;

    font-size: 1.3rem;
    line-height: 2rem;
  }
}

.button__login,
.button__logout {
  min-width: 8.4rem;

  border: 0.1rem solid var(--neh-gray);
  color: var(--white);
  background: var(--neh-gray);
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 600;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.2rem;
  text-align: center;

  cursor: pointer;
  user-select: none;

  transition: background 0.3s ease-out, color 0.3s ease-out;
}

.button--outline-white {
  min-width: 8.4rem;

  border: 0.1rem solid var(--white);
  color: var(--white);
  background-color: transparent;
  width: 50%;
  font-size: 1.6rem;

  margin-right: 1.6rem;

  font-style: normal;
  font-weight: 500;
  line-height: 3.2rem;
  padding: 0.8rem 0;
  border-radius: 0.2rem;
  text-align: center;

  cursor: pointer;
  user-select: none;
}

.button__login:last-child,
.button__logout:last-child,
.button--outline-white:last-child {
  margin-right: 0;
}

.button--outline-black {
  min-width: 8.4rem; /* 必要に応じて調整 */
  border: 0.1rem solid var(--black); /* 黒いアウトライン */
  color: var(--black); /* 文字色も黒 */
  background-color: transparent; /* 背景は透明 */
  width: 50%; /* 必要に応じて調整 */
  font-size: 1.6rem; /* フォントサイズ */
  margin-right: 1.6rem; /* ボタン間の余白 */
  font-style: normal;
  font-weight: 500;
  line-height: 3.2rem;
  padding: 0.8rem 0; /* 上下のパディング */
  border-radius: 0.2rem; /* 角の丸み */
  text-align: center; /* テキスト中央揃え */
  cursor: pointer; /* ポインターに変更 */
  user-select: none; /* テキスト選択不可 */
  transition: background 0.3s ease-out, color 0.3s ease-out; /* トランジション */
}

.button--outline-black:hover {
  background-color: rgba(0, 0, 0, 0.1); /* ホバー時の背景色 */
}

@media only screen and (min-width: 641px) {
  .button__login,
  .button__logout,
  .button--outline-white {
    padding: 0.8rem 1.2rem;

    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
